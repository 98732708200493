import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import ocrReducer from './ocrReducer';

// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
    customization: customizationReducer,
    ocr: ocrReducer
});

export default reducer;
