// assets
import {
    IconDashboard,
    IconDeviceAnalytics,
    IconPhoto,
    IconFileText,
    IconWritingSign,
    IconManualGearbox,
    IconApps,
    IconInfoSquare
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconPhoto,
    IconFileText,
    IconWritingSign,
    IconManualGearbox,
    IconApps,
    IconInfoSquare
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const dashboard = {
    id: 'dashboard',
    title: 'Pages',
    type: 'group',
    children: [
        {
            id: 'authentications',
            title: 'Scan Images',
            type: 'item',
            url: '/',
            icon: icons.IconPhoto,
            breadcrumbs: false
        },
        {
            id: 'authentication',
            title: 'Scan Pdfs',
            type: 'item',
            url: '/pdfs',
            icon: icons.IconFileText,
            breadcrumbs: false
        },
        {
            id: 'authentications54',
            title: 'Edit Images',
            type: 'item',
            url: '/editor',
            icon: icons.IconWritingSign,
            breadcrumbs: false
        },
        {
            id: 'authentication32',
            title: 'Downloads',
            type: 'item',
            url: '/downloads',
            icon: icons.IconApps,
            breadcrumbs: false
        },
        {
            id: 'authentication323',
            title: 'Getting Started',
            type: 'item',
            url: '/started',
            icon: icons.IconInfoSquare,
            breadcrumbs: false
        },
        {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            url: '/settings',
            icon: icons.IconManualGearbox,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
