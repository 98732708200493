const constants = {
    freeOCRLimit: 10,
    waitTimeInMiliSeconds: 5000,
    webhook: {
        baseUrl: 'https://webhook.fxnoob.workers.dev'
    },
    extension: {
        image_to_text_ocr: {
            tutorial: 'https://www.youtube.com/watch?v=c9hvz21ESys',
            chrome: {
                id: 'jgjlejdhmfpimggbicpffmpbnalcnhoo',
                url: 'https://chrome.google.com/webstore/detail/image-to-text/jgjlejdhmfpimggbicpffmpbnalcnhoo'
            },
            opera: {
                id: 'nlfnkkhgkofnahjdookfhcaagemjdabe',
                url: 'https://addons.opera.com/en/extensions/details/image-to-text-ocr/'
            },
            edge: {
                id: 'icgbomdceijejlokdmjpmgkojiliphma',
                url: 'https://microsoftedge.microsoft.com/addons/detail/icgbomdceijejlokdmjpmgkojiliphma'
            },
            firefox: {
                url: 'https://addons.mozilla.org/addon/image-to-text-ocr/'
            },
            pricing: {
                priceId: {
                    dev: 'price_1Kx3roSH1Cgu43ifdtLC7VlM', // 10 dollars
                    prod: 'price_1L9hvkSH1Cgu43iffMCcndpz', // 1050 rupees
                    get value() {
                        return process.env.NODE_ENV === 'development'
                            ? constants.extension.image_to_text_ocr.pricing.priceId.dev
                            : constants.extension.image_to_text_ocr.pricing.priceId.prod;
                    }
                }
            },
            paymentCancelURL: {
                dev: 'http://localhost:3000/account/',
                prod: 'https://app.imagetext.xyz/account',
                get value() {
                    return process.env.NODE_ENV === 'development'
                        ? constants.extension.image_to_text_ocr.paymentCancelURL.dev
                        : constants.extension.image_to_text_ocr.paymentCancelURL.prod;
                }
            },
            paymentSuccessURL: {
                dev: 'http://localhost:3000/validate/',
                prod: 'https://app.imagetext.xyz/validate',
                get value() {
                    return process.env.NODE_ENV === 'development'
                        ? constants.extension.image_to_text_ocr.paymentSuccessURL.dev
                        : constants.extension.image_to_text_ocr.paymentSuccessURL.prod;
                }
            },
            stripe: {
                apiKey: {
                    dev: 'pk_test_51IoYqnSH1Cgu43ifVsYyiSYo4I5gG0SMDtb0Ny12QiE9yHAFUlo6CAmhFCM2eM7WDhzwvaxH7uVYWHJ0GGQJK7X600E0nPQIdQ',
                    prod: 'pk_live_51IoYqnSH1Cgu43ifozXIV4DCnfYWxjgxaVL92xxmWwHR5Kgcy3algT4uaGtv1EKuL9seN8vsZEVvFploo8wbUIHp00KMgaSPDq',
                    get value() {
                        return process.env.NODE_ENV === 'development'
                            ? constants.extension.image_to_text_ocr.stripe.apiKey.dev
                            : constants.extension.image_to_text_ocr.stripe.apiKey.prod;
                    }
                }
            }
        },
        image_editor_web: {
            chrome: {
                id: 'cddmngomnoefbecedkiokcfhccohppil',
                url: 'https://chrome.google.com/webstore/detail/cddmngomnoefbecedkiokcfhccohppil'
            },
            opera: {
                id: 'jmelpilbhinmckilfapacicllplhkgin',
                url: 'https://addons.opera.com/en/extensions/details/image-editor-web/'
            },
            edge: {
                id: 'mmehjkpbnaclkoinedpndonlbjjbfjbp',
                url: 'https://microsoftedge.microsoft.com/addons/detail/mmehjkpbnaclkoinedpndonlbjjbfjbp'
            },
            firefox: {
                url: 'https://addons.mozilla.org/addon/image-editor-web/'
            }
        },
        object_finder_ai: {
            chrome: {
                id: 'bhfiacaoiiaajdjijafijbagomlkakfh',
                url: 'https://chrome.google.com/webstore/detail/bhfiacaoiiaajdjijafijbagomlkakfh'
            },
            opera: {
                id: 'nohdfknodnmbcclbhcadlgknjjidgafi',
                url: 'https://addons.opera.com/en/extensions/details/object-finder-ai/'
            },
            edge: {
                id: 'egmbdpbpplfcmcfpaengneloicfmcdao',
                url: 'https://microsoftedge.microsoft.com/addons/detail/egmbdpbpplfcmcfpaengneloicfmcdao'
            },
            firefox: {
                url: 'https://addons.mozilla.org/addon/object-finder-ai/'
            }
        },
        dictionary: {
            chrome: {
                id: 'pbknajakomimhnnaiddkbddboifjolbf',
                url: 'https://chrome.google.com/webstore/detail/dictionary-oxford-webster/pbknajakomimhnnaiddkbddboifjolbf'
            },
            opera: {
                id: 'pfkikbcnkmagkibicfmdpjgjbgjpghgk',
                url: 'https://addons.opera.com/en-gb/extensions/details/dictionary-oxford-webster-and-wikipedia/'
            },
            edge: {
                id: 'pbcjplaagkgfgihmbcbbmfdadhbekpeb',
                url: 'https://microsoftedge.microsoft.com/addons/detail/dictionary-oxford-webs/pbcjplaagkgfgihmbcbbmfdadhbekpeb'
            },
            firefox: {
                url: 'https://addons.mozilla.org/en-US/firefox/addon/dictionary/'
            },
            pricing: {
                priceId: {
                    dev: 'price_1L2PXgSITeZHFPIyqOv5yPSA', // 5 dollars
                    prod: 'price_1KrbuCSITeZHFPIyHYKGIXTO', // 5 dollars
                    get value() {
                        return process.env.NODE_ENV === 'development'
                            ? constants.extension.dictionary.pricing.priceId.dev
                            : constants.extension.dictionary.pricing.priceId.prod;
                    }
                }
            },
            paymentCancelURL: {
                dev: 'https://localhost:3000/pay-for-dictionary',
                prod: 'https://app.imagetext.xyz/pay-for-dictionary',
                get value() {
                    return process.env.NODE_ENV === 'development'
                        ? constants.extension.dictionary.paymentCancelURL.dev
                        : constants.extension.dictionary.paymentCancelURL.prod;
                }
            },
            paymentSuccessURL: {
                dev: 'https://localhost:3000/confirm-payment-for-dictionary',
                prod: 'https://app.imagetext.xyz/confirm-payment-for-dictionary',
                get value() {
                    return process.env.NODE_ENV === 'development'
                        ? constants.extension.dictionary.paymentSuccessURL.dev
                        : constants.extension.dictionary.paymentSuccessURL.prod;
                }
            },
            stripe: {
                apiKey: {
                    dev: 'pk_test_51J5SteSITeZHFPIy2b5hp7IIv8ji44I9i5JKYBJ77gYhYQau7jTYyDnjGKdCTxzi5lZLXotxA8vr8E7uMkPE7TTP00pJXVqtQj',
                    prod: 'pk_live_51J5SteSITeZHFPIygmFxGATLC1rCmgyYPE5sE3gZNavyRqBqEWeOW6Wcvk3bNV4dBc1htI9mBJHjJkYDLCgTI9g500ZXwECAu4',
                    get value() {
                        return process.env.NODE_ENV === 'development'
                            ? constants.extension.dictionary.stripe.apiKey.dev
                            : constants.extension.dictionary.stripe.apiKey.prod;
                    }
                }
            }
        },
        voice_typing: {
            chrome: {
                id: 'hmpihaioaacpehkghnkmnmgmihalkmdf',
                url: 'https://chrome.google.com/webstore/detail/voice-typing/hmpihaioaacpehkghnkmnmgmihalkmdf'
            },
            pricing: {
                priceId: {
                    dev: 'price_1L63TqSGxsTQeml5jJfczpaV', // 13.5 dollars
                    prod: 'price_1L63TqSGxsTQeml5jJfczpaV', // 13.5 dollars
                    get value() {
                        return process.env.NODE_ENV === 'development'
                            ? constants.extension.voice_typing.pricing.priceId.dev
                            : constants.extension.voice_typing.pricing.priceId.prod;
                    }
                }
            },
            paymentCancelURL: {
                dev: 'https://localhost:3000/pay-for-voice-typing',
                prod: 'https://app.imagetext.xyz/pay-for-voice-typing',
                get value() {
                    return process.env.NODE_ENV === 'development'
                        ? constants.extension.voice_typing.paymentCancelURL.dev
                        : constants.extension.voice_typing.paymentCancelURL.prod;
                }
            },
            paymentSuccessURL: {
                dev: 'https://localhost:3000/confirm-payment-voice-typing',
                prod: 'https://app.imagetext.xyz/confirm-payment-voice-typing',
                get value() {
                    return process.env.NODE_ENV === 'development'
                        ? constants.extension.voice_typing.paymentSuccessURL.dev
                        : constants.extension.voice_typing.paymentSuccessURL.prod;
                }
            },
            stripe: {
                apiKey: {
                    dev: 'pk_live_51IyOhfSGxsTQeml5PtdsmRdbE3Klc2N2vkwa9Bsr0Cig3laCyZCh74Ll4Ng3szXsaWrxj1rLbedWxivbOxI4wRxC00VbrF2XAd',
                    prod: 'pk_live_51IyOhfSGxsTQeml5PtdsmRdbE3Klc2N2vkwa9Bsr0Cig3laCyZCh74Ll4Ng3szXsaWrxj1rLbedWxivbOxI4wRxC00VbrF2XAd',
                    get value() {
                        return process.env.NODE_ENV === 'development'
                            ? constants.extension.voice_typing.stripe.apiKey.dev
                            : constants.extension.voice_typing.stripe.apiKey.prod;
                    }
                }
            }
        },
        translate: {
            tutorial: 'https://www.youtube.com/watch?v=BagcMZm8L5E',
            chrome: {
                id: 'pobhnbdhbklfjhlhpmoegnneonlnkbpo',
                url: 'https://chrome.google.com/webstore/detail/translate/pobhnbdhbklfjhlhpmoegnneonlnkbpo'
            },
            edge: {
                id: 'fgdbpaadcpabolagoajbhapbhbmpolcm',
                url: 'https://microsoftedge.microsoft.com/addons/detail/translate/fgdbpaadcpabolagoajbhapbhbmpolcm'
            },
            pricing: {
                priceId: {
                    dev: 'price_1M8lIYSH1Cgu43ifwRajxYvf', // 5 dollars
                    prod: 'price_1M8VatSH1Cgu43ifP17icRi6', // 408.35 rupees
                    get value() {
                        return process.env.NODE_ENV === 'development'
                            ? constants.extension.translate.pricing.priceId.dev
                            : constants.extension.translate.pricing.priceId.prod;
                    }
                }
            },
            paymentCancelURL: {
                dev: 'http://localhost:3000/pay-for-translate/',
                prod: 'https://app.imagetext.xyz/pay-for-translate',
                get value() {
                    return process.env.NODE_ENV === 'development'
                        ? constants.extension.translate.paymentCancelURL.dev
                        : constants.extension.translate.paymentCancelURL.prod;
                }
            },
            paymentSuccessURL: {
                dev: 'http://localhost:3000/confirm-payment-translate/',
                prod: 'https://app.imagetext.xyz/confirm-payment-translate',
                get value() {
                    return process.env.NODE_ENV === 'development'
                        ? constants.extension.translate.paymentSuccessURL.dev
                        : constants.extension.translate.paymentSuccessURL.prod;
                }
            },
            stripe: {
                apiKey: {
                    dev: 'pk_test_51IoYqnSH1Cgu43ifVsYyiSYo4I5gG0SMDtb0Ny12QiE9yHAFUlo6CAmhFCM2eM7WDhzwvaxH7uVYWHJ0GGQJK7X600E0nPQIdQ',
                    prod: 'pk_live_51IoYqnSH1Cgu43ifozXIV4DCnfYWxjgxaVL92xxmWwHR5Kgcy3algT4uaGtv1EKuL9seN8vsZEVvFploo8wbUIHp00KMgaSPDq',
                    get value() {
                        return process.env.NODE_ENV === 'development'
                            ? constants.extension.translate.stripe.apiKey.dev
                            : constants.extension.translate.stripe.apiKey.prod;
                    }
                }
            }
        },
        saveTo: {
            chrome: {
                url: 'https://chrome.google.com/webstore/detail/save-to/ofifhemkgcbeneclidajibkcjllgmjfp'
            }
        }
    },
    premium: {
        endpoint: 'https://app.imagetext.xyz/',
        pricing: {
            short: '$13',
            description: '$13 for a lifetime.',
            full: 'inr 1050 ~ $13'
        }
    },
    support: {
        feedbackForm: 'https://forms.gle/iaFrarBtMp2YDFhB6',
        youtube: 'https://www.youtube.com/fxnoob'
    },
    server: {
        frontEnd: {},
        endpoint: {
            local: 'http://127.0.0.1:8787',
            prod: 'https://webhook.fxnoob.workers.dev'
        }
    }
};
export default constants;
