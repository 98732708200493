import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// pdfs routing
const Pdfs = Loadable(lazy(() => import('views/pdfs/Default')));

// settings routing
const Settings = Loadable(lazy(() => import('views/settings')));

// validate routing
const Validate = Loadable(lazy(() => import('views/validate')));

// editor routing
const Editor = Loadable(lazy(() => import('views/editor')));

// downloads routing
const Downloads = Loadable(lazy(() => import('views/downloads')));

// started routing
const Started = Loadable(lazy(() => import('views/started')));

// screen routing
const Screen = Loadable(lazy(() => import('views/screen')));

// desktop routing
const Desktop = Loadable(lazy(() => import('views/desktop')));

// scan routing
const Scan = Loadable(lazy(() => import('views/scan')));

// account routing
const Account = Loadable(lazy(() => import('views/account')));

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = ({ isLoggedIn, isPaid }) => ({
    path: '/',
    element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
    children: [
        {
            path: '/',
            element: isPaid ? <DashboardDefault /> : <Navigate to="/account" />
        },
        {
            path: '/images',
            element: isPaid ? <DashboardDefault /> : <Navigate to="/account" />
        },
        {
            path: '/screen',
            element: isPaid ? <Screen /> : <Navigate to="/account" />
        },
        {
            path: '/desktop',
            element: isPaid ? <Desktop /> : <Navigate to="/account" />
        },
        {
            path: '/scan',
            element: isPaid ? <Scan /> : <Navigate to="/account" />
        },
        {
            path: '/pdfs',
            element: isPaid ? <Pdfs /> : <Navigate to="/account" />
        },
        {
            path: '/settings',
            element: isPaid ? <Settings /> : <Navigate to="/account" />
        },
        {
            path: '/validate',
            element: isLoggedIn ? <Validate /> : <Navigate to="/login" />
        },
        {
            path: '/account',
            element: <Account />
        },
        {
            path: '/editor',
            element: <Editor />
        },
        {
            path: '/started',
            element: <Started />
        },
        {
            path: '/downloads',
            element: <Downloads />
        }
    ]
});

export default MainRoutes;
