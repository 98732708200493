import React, { createContext, useEffect, useState, useContext } from 'react';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebase, checkEntry } from '../services/firebaseService';
import { getBrowserLocale, sendMessageToImageText } from '../services/helper';
import StorageManager from '../services/StorageManager';
import { USER_LANGUAGE } from '../services/StorageKeys';
import Loader from 'ui-component/Loader';
import { useNavigate } from 'react-router';

export const AuthContext = createContext();
const auth = firebase.auth();

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [user, loading, error] = useAuthState(auth);
    const init = async (user) => {
        if (user) {
            const { isPaid, locale } = await checkEntry('users', user?.email);
            user.paid = isPaid;
            user.locale = locale || getBrowserLocale();
            StorageManager.put(USER_LANGUAGE, user.locale);
            // send signal to extension to change url to ad-free endpoint
            if (typeof chrome !== 'undefined') {
                sendMessageToImageText(
                    '/upgrade',
                    {
                        endpoint: user.paid ? 'https://app.imagetext.xyz' : 'https://imagetext.xyz'
                    },
                    () => {}
                );
            }
        }
        setCurrentUser(user);
        if (!loading) {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        init(user).catch((e) => console.log(e));
    }, [user, loading]);

    if (isLoading || loading) {
        return <Loader />;
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
