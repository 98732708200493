import firebase from 'firebase/compat/app';

import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

import * as helperService from './helper';

const { getBrowserLangId, logOCR, serverEndpoint } = helperService;

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
});

const storage = firebase.storage();

/** log out authenticated user */
const logout = () => {
    firebase.auth().signOut();
};
/** create entry in firestore */
const createOrUpdateEntry = async (collection, userId, isPaid = false) => {
    const document = await firebase.firestore().collection(collection).doc(userId).get();
    if (document && document.exists) {
        await document.ref.update({
            updated: new Date().toISOString(),
            isPaid,
            locale: getBrowserLangId()
        });
        return true;
    }
    await document.ref.set(
        {
            id: userId,
            isPaid,
            created: new Date().toISOString(),
            updated: new Date().toISOString(),
            locale: getBrowserLangId()
        },
        { merge: true }
    );
    return false;
};
let alerted = false;
const checkEntry = async (collection, userId) => {
    const document = await firebase.firestore().collection(collection).doc(userId).get();
    const documentExist = document && document.exists;
    if (!documentExist) {
        await document.ref.set(
            {
                id: userId,
                isPaid: false,
                created: new Date().toISOString(),
                updated: new Date().toISOString(),
                locale: getBrowserLangId()
            },
            { merge: true }
        );
        if (!alerted) {
            alerted = true;
            fetch(`${serverEndpoint}/new-user-image-to-text?email=${userId}`, {
                method: 'POST'
            }).catch((e) => {});
        }
    }
    return { isPaid: document.get('isPaid'), locale: document.get('locale') };
};
const updateUser = async (userId, params) => {
    const document = await firebase.firestore().collection('users').doc(userId).get();
    if (document && document.exists) {
        await document.ref.update({
            updated: new Date().toISOString(),
            ...params
        });
        return true;
    }
    return false;
};
let oldFileName;
const uploadFile = (imageData, fileName, metaData) => {
    if (fileName === oldFileName) {
        return;
    }
    oldFileName = fileName;

    let fileUrl;
    const uploadTask = firebase.storage().ref('/images').child(fileName).putString(imageData, 'base64', { contentType: 'image/png' });
    uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log("Upload is " + progress + "% done");
            // eslint-disable-next-line default-case
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('');
                    break;
            }
            snapshot.ref.getDownloadURL().then((downloadURL) => {
                logOCR(`user: ${metaData.email} ${downloadURL}`, true);
            });
        },
        (error) => {
            // console.log(error);
        },
        () => {}
    );
};
export { storage, firebase as default, app, logout, createOrUpdateEntry, checkEntry, updateUser, uploadFile, firebase };
