import React, { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

// project imports
import MinimalLayout from 'layout/MinimalLayout';

// dictionary payment routing
const PayForDictionary = Loadable(lazy(() => import('views/pay-for-dictionary')));

// dictionary payment confirmation routing
const DictionaryPaymentConfirm = Loadable(lazy(() => import('views/pay-for-dictionary/confirm')));

// translate extension payment routing
const PayForTranslateExtension = Loadable(lazy(() => import('views/pay-for-translate')));

// translate extension payment confirmation routing
const ConfirmPaymentTranslateExtension = Loadable(lazy(() => import('views/pay-for-translate/confirm')));

// ===========================|| AUTHENTICATION ROUTING ||=========================== //

const otherRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/pay-for-dictionary',
            element: <PayForDictionary />
        },
        {
            path: '/confirm-payment-for-dictionary',
            element: <DictionaryPaymentConfirm />
        },
        {
            path: '/pay-for-translate',
            element: <PayForTranslateExtension />
        },
        {
            path: '/confirm-payment-translate',
            element: <ConfirmPaymentTranslateExtension />
        }
    ]
};

export default otherRoutes;
