import React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import '@fontsource/poppins';
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { LanguageProvider } from 'context/LanguageContext';
import { AuthProvider } from 'context/AuthContext';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <AuthProvider>
                <LanguageProvider>
                    <ThemeProvider theme={themes(customization)}>
                        <CssBaseline />
                        <NavigationScroll>
                            <Routes />
                        </NavigationScroll>
                    </ThemeProvider>
                </LanguageProvider>
            </AuthProvider>
        </StyledEngineProvider>
    );
};

export default App;
