import { useContext } from 'react';
import { useRoutes } from 'react-router-dom';

// project
import { AuthContext } from '../context/AuthContext';
// routes
import MainRoutes from './MainRoutes';
import LegalRoutes from './legalRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import OtherRoutes from './otherRoutes';
import Route404 from './404';

// ===========================|| ROUTING RENDER ||=========================== //

export default function ThemeRoutes() {
    const { currentUser } = useContext(AuthContext);
    const isLoggedIn = !!currentUser;
    const isPaid = currentUser && currentUser.paid;
    return useRoutes([
        MainRoutes({ isLoggedIn, isPaid }),
        AuthenticationRoutes({ isLoggedIn, isPaid }),
        LegalRoutes,
        OtherRoutes,
        Route404
    ]);
}
